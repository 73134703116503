import React from 'react';
import './ClassBody.css';
import ClassBodyElement from './ClassBodyElement';
import { renderDayOfWeek, renderTimetableBlueprintEntry } from '../library/DateTimeFuncs.js';

const unavailableTerm = "Unavailable";
const unavailableProgrammeNameTerm = "Programme name unknown";
const classLearningMethod = "CLASS";
const homeBasedLearningMethod = "HBL";
const nohoLearningMethod = "NOHO";
const oneDayWanangaLearningMethod = "ONE-DAY";
const onlineLearningMethod = "ONLINE";

function convertLearningMethod(learningMethod) {
  switch (learningMethod) {
    case classLearningMethod:
      return "Class";
    case homeBasedLearningMethod:
      return "Home Based Learning";
    case nohoLearningMethod:
      return "Noho";
    case oneDayWanangaLearningMethod:
      return "One Day Wānanga";
    case onlineLearningMethod:
      return "eLearning";
    case "??":
      return unavailableTerm; // TODO SO: wouldn't that be better to be "Unknown"?
    default:
      return learningMethod;
  }
}

function determineLearningMethodSubText(learningMethod) {
  switch (learningMethod) {
    case classLearningMethod:
    case homeBasedLearningMethod:
    case nohoLearningMethod:
    case oneDayWanangaLearningMethod:
    case onlineLearningMethod:
      return "Secondary learning methods unavailable, if any";

    default:
      return ""; // no sub text if we don't know what's going on
  }
}

// render the class body
const ClassBody = (props) => {
  const isFirstLoad = props.isFirstLoad;
  const theClass = props.theClass;

  // determine statuses and other info
  const isClassDefined = theClass !== undefined;
  const registryUnitName = isClassDefined ? !!theClass.registryUnitName ? theClass.registryUnitName : unavailableTerm : undefined;
  const programmeCode = isClassDefined ? !!theClass.programmeCode ? theClass.programmeCode : unavailableTerm : undefined;
  const programmeName = isClassDefined ? !!theClass.programmeName ? theClass.programmeName : unavailableProgrammeNameTerm : undefined;
  const primaryLearningMethod = isClassDefined ? !!theClass.primaryLearningMethod ? theClass.primaryLearningMethod : unavailableTerm : undefined;
  const isApproved = isClassDefined ? theClass.isApproved : false;
  const approvalTime = isClassDefined ? theClass.approvalTime : undefined;
  const isCancelled = isClassDefined ? theClass.isCancelled : false;
  const cancelTime = isClassDefined ? theClass.cancelTime : undefined;
  const isEnrolling = isClassDefined ? theClass.isEnrolling : false;
  const stopEnrolTime = isClassDefined ? theClass.stopEnrolTime : undefined;
  const isWaitlistingDefined = isClassDefined ? theClass.isWaitlisting !== undefined && theClass.isWaitlisting !== null : false;
  const isWaitlisting = isClassDefined ? theClass.isWaitlisting : undefined;
  const waitlistTime = isClassDefined ? theClass.waitlistTime : undefined;
  const isOpenForApplication = isClassDefined ? theClass.isOpenForApplication : false;
  const uniqueRef = isClassDefined ? theClass.uniqueRef : undefined;
  const plannedTauiraNumber = isClassDefined ? theClass.plannedTauiraNumber === 0 ? unavailableTerm : theClass.plannedTauiraNumber : unavailableTerm;
  const startDate = isClassDefined ? !!theClass.startDate ? theClass.startDate : unavailableTerm : undefined;
  const doesMagicHappen = isClassDefined ? theClass.makeMagicHappen : false;

  // handle the main delivery site (aka MDS)
  const mdsId = isClassDefined ? !!theClass.mainDeliverySiteId ? theClass.mainDeliverySiteId : undefined : undefined;
  const mdsLocationCode = isClassDefined ? !!theClass.mainDeliverySiteLocationCode ? theClass.mainDeliverySiteLocationCode : undefined : undefined;
  const mdsDetails = isClassDefined ? !!theClass.mainDeliverySiteDetails ? theClass.mainDeliverySiteDetails : undefined : undefined;
  const deliverySiteViewerUrl = process.env.REACT_APP_APP_DELIVERYSITEVIEWER_BASE || '';
  const mdsContent = mdsId === "00000000-0000-0000-0000-000000000000" || mdsLocationCode === undefined
    ? unavailableTerm // we don't have a main delivery site
    : <>
      <a href={deliverySiteViewerUrl + '?locationCode=' + mdsLocationCode}>{mdsLocationCode}</a>
      {mdsDetails !== undefined ? mdsDetails : ''}
    </>

  // handle the start year
  const startYear = isClassDefined
    ? theClass.startYear === undefined || theClass.startYear == null || theClass.startYear === 0
      ? unavailableTerm
      : theClass.startYear
    : undefined;

  // handle blueprint entries
  const timetableBpEntries = isClassDefined
    ? theClass.timetableBpEntries !== undefined ? theClass.timetableBpEntries : []
    : [];
  const timetableBpEntriesTime = isClassDefined ? theClass.timetableBpEntriesTime : undefined;
  const timetableBpEntriesText = timetableBpEntries.length === 0
    ? unavailableTerm
    : timetableBpEntries.length === 1
      ? renderTimetableBlueprintEntry(timetableBpEntries[0])
      : renderDayOfWeek(timetableBpEntries[0].day) + ", ..."; // TODO SO: we have multiple days, so render them all

  const programmeViewerUrl = process.env.REACT_APP_APP_PROGRAMMEVIEWER_BASE || '';
  const programmeCodeAnchor = programmeViewerUrl && programmeCode !== unavailableTerm
    ? <a href={programmeViewerUrl + '?programmeCode=' + programmeCode}>{programmeCode}</a>
    : unavailableTerm;
  const programmeInfo = <>{programmeCodeAnchor}{!!programmeName ? programmeCode === unavailableTerm ? "" : programmeName : unavailableProgrammeNameTerm}</>

  // for 2024 classes prior October 1 2023 show a disclaimer.
  // disclaimer is no longer displayed after October 1 2023
  const classDisclaimer = "Subject to 2024 enrolments being open";
  let displayDisclaimer = "";
  let currentDate = new Date();
  let disclaimerDate = new Date("2023-10-01");

  if (isEnrolling && currentDate < disclaimerDate && typeof uniqueRef === "string" && uniqueRef.substring(2, 4) === "24")
    displayDisclaimer = classDisclaimer;

  // render either that we're loading data (if loaded for the first time), or the class, or that the class does not exist
  let content = isClassDefined
    ? <>
      <ClassBodyElement title="MERI KIRIHIMETE" text="MERRY CHRISTMAS!" icon="class-viewer-images/santa.png" subText="from Alix, Nichola, Gayathri, Chris, Titus, and Lenin" visible={doesMagicHappen} />
      <ClassBodyElement title="Registry unit" text={registryUnitName} />
      <ClassBodyElement title="Programme" text={programmeInfo} />
      <ClassBodyElement title="Class code" text={theClass.classCode} />
      <ClassBodyElement title="Learning method" text={convertLearningMethod(primaryLearningMethod)} subText={determineLearningMethodSubText(primaryLearningMethod)} />
      <ClassBodyElement title="Delivery site" text={mdsContent} />
      <ClassBodyElement
        title="Status"
        icon={isApproved ? "class-viewer-images/approved.svg" : "class-viewer-images/not-approved.svg"}
        iconAlt="approval icon"
        text={isApproved ? "Approved" : "Not approved"}
        textColor={isApproved ? "approved" : undefined}
        dateAndTime={isApproved ? approvalTime : undefined}
        visible={!isCancelled} />
      <ClassBodyElement
        title=""
        icon={isEnrolling ? "class-viewer-images/enrolling.svg" : "class-viewer-images/not-enrolling.svg"}
        iconAlt="enrollment icon"
        text={isEnrolling ? "Enrolling" : "Not enrolling"}
        textColor={isEnrolling ? "enrolling" : undefined}
        dateAndTime={isEnrolling ? undefined : stopEnrolTime}
        dateAndTimePrefix={isEnrolling ? undefined : "Stopped "}
        visible={!isCancelled}
        subText={displayDisclaimer} />
      <ClassBodyElement
        title=""
        icon={isWaitlistingDefined ? isWaitlisting ? "class-viewer-images/waitlisting.svg" : "class-viewer-images/not-waitlisting.svg" : undefined}
        iconAlt="waitlisting icon"
        text={isWaitlistingDefined ? isWaitlisting ? "Waitlisting" : "Not waitlisting" : "Waitlisting unknown"}
        textColor={isWaitlistingDefined && isWaitlisting ? "waitlisting" : undefined}
        dateAndTime={isWaitlistingDefined && isWaitlisting ? waitlistTime : undefined}
        dateAndTimePrefix={isWaitlistingDefined && isWaitlisting ? "Opened " : undefined}
        visible={!isCancelled} />
      <ClassBodyElement
        title=""
        icon={isOpenForApplication ? "class-viewer-images/open-for-application.svg" : "class-viewer-images/not-open-for-application.svg"}
        iconAlt="open for application icon"
        text={isOpenForApplication ? "Open for application" : "Not open for application"}
        textColor={isOpenForApplication ? "openForApplication" : undefined}
        subText={displayDisclaimer}
        visible={!isCancelled} />
      <ClassBodyElement
        title="Status"
        icon="class-viewer-images/cancelled.svg"
        iconAlt="cancellation icon"
        text="Cancelled"
        textColor="cancelled"
        dateAndTime={cancelTime}
        visible={isCancelled} />
      <ClassBodyElement title="Start year" text={startYear} />
      <ClassBodyElement title="Start date" text={startDate} />
      <ClassBodyElement title="Planned tauira number" text={plannedTauiraNumber} />
      <ClassBodyElement
        testId="timetable"
        title="Timetable"
        text={timetableBpEntriesText}
        dateAndTime={timetableBpEntriesTime}
        dateAndTimePrefix="Retrieved from an EP, updated "
        visible={!isCancelled} />
    </>
    : isFirstLoad
      ? // this is the very first load
      <ClassBodyElement title="Loading ..." className="notification" />
      : // this is a subsequent load
      <ClassBodyElement title="Class does not exist." className="error" />

  // render
  return content;
}

export default ClassBody;